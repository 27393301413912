<!--
 * @Author: your name
 * @Date: 2022-03-04 17:32:35
 * @LastEditTime: 2024-05-07 17:29:52
 * @LastEditors: 郭长生
 * @FilePath: \ADIS\src\components\findKey\Index.vue
-->
<template>
  <div>
    <div v-if="!isSuccess">
      <p class="titleName">{{$t('system.tab2')}}</p>
      <div v-if="step==0" class="noQaTips">
        <p>{{$t('system.forgetPassword.noQaTip1')}}</p>
        <p>{{$t('system.forgetPassword.noQaTip2')}}</p>
        <el-button 
            style="margin: 20px 0"
            type="primary" 
            @click="toSetQa()" @keyup.space.prevent.native @keydown.enter.prevent.native>
              {{$t('system.password.toSet')}}
        </el-button>
      </div>
      <div v-else>
        <el-form :model="formChangePass" ref="changePassForm" :rules="changePassRules" label-width="160px" class="formBox">
          <el-form-item :label="$t('system.password.item1')" prop="old_password">
            <el-input v-model="formChangePass.old_password" :placeholder="$t('system.password.pleaseEnter')" type="password" clearable></el-input>
          </el-form-item>
          <span class="findKey"><FindKey /></span>
          <el-form-item :label="$t('system.password.item2')" prop="password">
            <el-input v-model="formChangePass.password" :placeholder="$t('system.password.pleaseEnter')" type="password" clearable></el-input>
          </el-form-item>
          <el-form-item :label="$t('system.password.item3')" prop="confirm_password">
            <el-input v-model="formChangePass.confirm_password" :placeholder="$t('system.password.pleaseEnter')" type="password" clearable></el-input>
          </el-form-item>
        </el-form>
        <el-divider></el-divider>
        <p class="tips">
          <p>{{$t('system.password.rule')}}</p>
          <p>{{$t('system.password.rule1')}}</p>
          <p>{{$t('system.password.rule2')}}</p>
        </p>
        <el-button 
            type="primary" 
            @click="submit()" 
            :disabled="!(formChangePass.old_password && formChangePass.password && formChangePass.confirm_password)" @keyup.space.prevent.native @keydown.enter.prevent.native>
              {{$t('system.determine')}}
        </el-button>
      </div>

    </div>
    <div v-else class="successResult">
      <i class="el-icon-success resultIcon" />
      <div class="resultTitle">{{$t('system.password.changePasswordSuccess')}}</div>
      <el-button type="primary" @click="toInitialzation()" @keyup.space.prevent.native @keydown.enter.prevent.native>{{$t('system.back')}}</el-button>
    </div>
  </div>

</template>

<script lang="ts">
import {
	onMounted,
  reactive,
  ref,
  unref,
  toRefs,
  defineComponent,
} from "vue";
import FindKey from '@/components/find-key/Index.vue'
import { useRouter, useRoute } from "vue-router";
import { useStore } from '@/store'
import moment from "moment";
import { useI18n } from 'vue-i18n'
import i18n from '@/locales/index'
import { ElForm, ElMessageBox, ElMessage } from 'element-plus';
import { repassword, editUserPassword,editEmailUserPassword, getUserPassQa,getEmailUserPassQa } from '@/apis/user'
let _t = i18n.global.t
let re = /^[a-zA-Z0-9 ；，、’‘：？——【】《》“”！!'"_<>`,·。≈{}~～()-￥%……（^）|?,@#$&()|;:"<>*\\/\n]+$/;
interface formObject {
  old_password?: string,
  password?: string,
  confirm_password?: string,
}
interface paramsType {
  formChangePass?: formObject,
  changePassRules?: Object,
  isSuccess: boolean,
  step: number,
  isAdmin: boolean,
}

export default {
  components: {
    FindKey,
  },
  props: [],
  setup(props, context) {
    const changePassForm = ref(ElForm);
    const store = useStore()
    const checRekNewKey = (rule: any, value: any, callback: any) => {
      if (value === '') {
        callback(new Error(_t('system.password.confirmPasswordempty')))
      } else if(value.length>20){
        callback(new Error(_t('system.password.confirmPasswordRule1')))
      } else if(value.length<6) {
        callback(new Error(_t('system.password.confirmPasswordRule2')))
      } else if(!re.test(value)) {
        callback(new Error(_t('system.password.passwordRule3')))
      } else if(state.formChangePass && (value !== state.formChangePass?.password)) {
        callback(new Error(_t('system.password.passwordRule4')))
      } else {
        callback()
      }
    }
    const checkNewKey = (rule: any, value: any, callback: any) => {
      if (value === '') {
        callback(new Error(_t('system.password.passwordempty')))
      } else if(value.length>20){
        callback(new Error(_t('system.password.passwordRule1')))
      } else if(value.length<6) {
        callback(new Error(_t('system.password.passwordRule2')))
      } else if(!re.test(value)) {
        callback(new Error(_t('system.password.passwordRule3')))
      } else if(state.formChangePass && state.formChangePass?.confirm_password !== '') {
        unref(changePassForm).validateField('confirm_password')
      }
      callback()
    }
    const state: paramsType = reactive({
      isAdmin: store.state.user.roles.indexOf('admin')>=0,
      isSuccess: false,
      step: 0,
      formChangePass: { 
        old_password: '',
        password: '',
        confirm_password: ''
      },
      changePassRules: {
        old_password: [{ required: true, message: _t('system.password.currentPasswordEmpty'), trigger: 'blur' }],
        password: [{ validator: checkNewKey, trigger: 'blur' }],
        confirm_password: [{ validator: checRekNewKey, trigger: 'blur' }],
      },
    })
    const methods = reactive({
      submit() {
        unref(changePassForm).validate((valid) => {
          if (valid) {
            if(store.state.user.name==''){
              // 邮箱用户更新
              const params = Object.assign(state.formChangePass, {
                company_id: store.state.user.company,
                email:store.state.user.email
              });
              editEmailUserPassword(params).then((res: any) => {
                if(res) {
                  if (res.code===200) {
                    unref(changePassForm).resetFields()  
                    state.isSuccess = true    
                  } else if (res.code===40003) {
                    ElMessage.warning(_t('system.password.passwordWrong'))
                  } else {
                    ElMessage.warning(_t('system.password.changePasswordError'))
                  }
                }
              }).catch(error => console.log('error', error))
            }else{
              //用户名用户更新
              const params = Object.assign(state.formChangePass, {
                company_id: store.state.user.company,
              });
              editUserPassword(store.state.user.name, params).then((res: any) => {
                if(res) {
                  if (res.code===200) {
                    unref(changePassForm).resetFields()  
                    state.isSuccess = true    
                  } else if (res.code===40003) {
                    ElMessage.warning(_t('system.password.passwordWrong'))
                  } else {
                    ElMessage.warning(_t('system.password.changePasswordError'))
                  }
                }
              }).catch(error => console.log('error', error))
            }
          } else {
            return false
          }
        })
      },
      getCurrentQa() {
        if(store.state.user.name==''){
          getEmailUserPassQa({ email: store.state.user.email }).then((res: any) => {
            if(res.code===200) {
              if(res.data?.lists?.length>0) {
                state.step = 1
              } else if(!state.isAdmin) { // 非admin用户不需要填安全问题
                state.step = 1
              } else {
                state.step = 0
              }
            } else {
              ElMessage.warning(_t('system.qa.getQaListError')) 
            }
          })
        }else{
          getUserPassQa({ name: store.state.user.name }).then((res: any) => {
            if(res.code===200) {
              if(res.data?.lists?.length>0) {
                state.step = 1
              } else if(!state.isAdmin) { // 非admin用户不需要填安全问题
                state.step = 1
              } else {
                state.step = 0
              }
            } else {
              ElMessage.warning(_t('system.qa.getQaListError')) 
            }
          })
        }
        
      },
      toSetQa() {
        context.emit('jumpToQuestion')
      },
      toInitialzation() {
        state.isSuccess = false
      },
      reset() {
        state.step == 1 ? unref(changePassForm).resetFields() : null
      }

    });
    onMounted(() => {
    })
    return {
      ...toRefs(state),
      ...toRefs(methods),
      changePassForm,
    }
  }
}
</script>
<style lang="scss" scoped>
  .formBox{
    position: relative;
    .findKey {
      position: absolute;
      top: 0;
      left: 440px;
    }
    .el-input {
      width: 280px;
    }
  }
  .tips{
    font-size: 13px;
    color: #afafaf;
    line-height: 14px;
  }
  .noQaTips{
    font-size: 14px;
  }
  .titleName {
	  font-size: 18px;
	  font-weight: bold;
	  margin: 0 0 20px;
  }
  .successResult{
    text-align: center;
    padding: 50px;
    .resultIcon{
      color: #5bd171;
      font-size: 50px;
      margin: 0 0 20px;
    }
    .resultTitle{
      font-size: 16px;
      margin: 0 0 16px;
      color: #303133;
    }
  }
</style>
