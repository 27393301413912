
import {
  onMounted,
  reactive,
  ref,
  unref,
  toRefs,
  defineComponent,
  computed
} from "vue";
import {
  getPassQuestionList,
  getUserPassQa,
  getEmailUserPassQa,
  checkQa,
  addUserQa,
  editUserQa
} from "@/apis/user";
import { useStore } from "@/store";
import { getLanguage } from "@/utils/cookies";
import { useRouter, useRoute } from "vue-router";
import moment from "moment";
import { useI18n } from "vue-i18n";
import i18n from "@/locales/index";
import { ElForm, ElMessage, ElMessageBox } from "element-plus";
const _t = i18n.global.t;

interface qalistType {
  answer: string;
  question: string;
}
interface paramsType {
  formNewQa: any;
  step: number;
  questionList: Array<{[key:string]:any}>;
  oldQuestionList: Array<qalistType>;
  oldQaRules: any;
  newQaRules: any;
  checkOldQa: string;
  isSuccess: boolean;
  language: string;
  maginstep: boolean;
}

export default {
  props: [],
  setup(props, context) {
    const oldForm = ref(ElForm);
    const newForm = ref(ElForm);
    const store = useStore();

    const state: paramsType = reactive({
      formNewQa: { question: "", answer: "" },
      step: 1,
      questionList: [],
      oldQuestionList: [{ answer: "", question: "" }],
      checkOldQa: "",
      isSuccess: false,
      language: getLanguage() || "en",
      maginstep: false,
      customRule: async (i, rule, value, callback) => {
        if (!value || value.trim() === "") {
          callback(new Error(_t("system.qa.currentAnswer")));
        } else {
          const newlist = [
            {
              question: state.oldQuestionList[i].question,
              answer: value
            }
          ];
          let res = await checkQa(store.state.user.name, { data: newlist });
          if (res) {
            if (res.code === 200) {
              state.checkOldQa = res.data.token;
              callback();
            } else if (res.code === 40005) {
              callback(new Error(_t("system.qa.answerError")));
            } else {
              callback(new Error(res.msg));
            }
          }
          return Promise.reject(_t("system.qa.answerError"));
        }
        // }).catch((error) => { console.log('error>>>>', error); callback(new Error(_t('system.qa.failRequeat'))) })
      },
      oldQaRules: {},
      newQaRules: {
        question: [
          { required: true, message: _t("system.choice"), trigger: "change" }
        ],
        answer: [
          {
            validator: (
              rule: any,
              value: any,
              callback: any,
              source,
              options
            ) => {
              if (!value || value.trim() === "") {
                callback(new Error(_t("system.qa.currentAnswer")));
              } else {
                callback();
              }
            },
            trigger: "blur"
          }
        ]
      }
    });
    const methods = reactive({
      nextButton() {
        if (state.step == 1) {
          setTimeout(() => {
            unref(oldForm).validate((valid: boolean) => {
              if (valid) {
                methods.getQaList();
                methods.nextStep();
              } else {
                return false;
              }
            });
          }, 1000);
        } else {
          unref(newForm).validate((valid: boolean) => {
            const obj = { data: state.oldQuestionList };
            if (state.checkOldQa) {
              editUserQa(
                store.state.user.name,
                Object.assign(state.formNewQa, obj),
                state.checkOldQa
              ).then((res: any) => {
                if (res.code === 200) {
                  unref(newForm).resetFields();
                  state.isSuccess = true;
                  state.step = 1;
                  methods.getCurrentQa();
                } else {
                  ElMessage.warning(_t("system.qa.setQaError"));
                }
              });
            } else {
              addUserQa(
                store.state.user.name,
                Object.assign(state.formNewQa, obj)
              ).then((res: any) => {
                if (res.code === 200) {
                  unref(newForm).resetFields();
                  state.isSuccess = true;
                  state.step = 1;
                  methods.getCurrentQa();
                } else {
                  ElMessage.warning(_t("system.qa.setQaError"));
                }
              });
            }
          });
        }
      },
      nextStep() {
        state.step += 1;
      },
      getCurrentQa() {
        if(store.state.user.name==''){
          getEmailUserPassQa({ email: store.state.user.email }).then((res: any) => {
            if (res.code === 200) {
              if (res.data?.lists?.length > 0) {
                state.oldQuestionList = [
                  // 多个问题此处需要修改
                  {
                    question:
                      state.language === "zh-cn"
                        ? res.data.lists[0].content_cn
                        : state.language === "es"
                        ? res.data.lists[0].content_es
                        : res.data.lists[0].content_en,
                    answer: ""
                  }
                ];
                state.step = 1;
                unref(oldForm) ? unref(oldForm).resetFields() : null;
              } else {
                state.step = 2;
                state.oldQuestionList = [];
                methods.getQaList();
              }
            } else {
              ElMessage.warning(_t("system.qa.getQaListError"));
            }
          });
        }else{
          getUserPassQa({ name: store.state.user.name }).then((res: any) => {
            if (res.code === 200) {
              if (res.data?.lists?.length > 0) {
                // state.oldQuestionList = res.data.lists.map((item, i) => {
                //   return {
                //     question: getLanguage() === 'en' ? item.content_en : item.content_cn,
                //     answer: ''
                //   }
                // })
                state.oldQuestionList = [
                  // 多个问题此处需要修改
                  {
                    question:
                      state.language === "zh-cn"
                        ? res.data.lists[0].content_cn
                        : state.language === "es"
                        ? res.data.lists[0].content_es
                        : res.data.lists[0].content_en,
                    answer: ""
                  }
                ];
                state.step = 1;
                unref(oldForm) ? unref(oldForm).resetFields() : null;
              } else {
                state.step = 2;
                state.oldQuestionList = [];
                methods.getQaList();
              }
            } else {
              ElMessage.warning(_t("system.qa.getQaListError"));
            }
          });
        }
        
      },
      getQaList() {
        getPassQuestionList().then((res: any) => {
          if (res.code === 200) {
            state.questionList = res.data.lists;
          } else {
          }
        });
      },
      toInitialzation() {
        state.isSuccess = false;
      }
    });
    onMounted(() => {});
    return {
      ...toRefs(state),
      ...toRefs(methods),
      oldForm,
      newForm
    };
  }
};
